<template>
    <div>
    <div class="wrapper innerBg">
        <!--header-->
        <div class="header">
          <div class="pagesize">
            <div class="headerNav">
              <router-link class="headerlogo" to="/index"><img src="./images/logo.png" alt="">臺北市士林區公所能源管理系統</router-link>
              <div class="state web">
                <div class="circle"></div>通訊正常
              </div>
              <ul class="headerNav_menu web">
            <li><router-link to="/index">首頁</router-link></li>
            <li><router-link to="./public" >公共用電</router-link></li>
            <li><router-link to="./floor" >樓層用電</router-link></li>
            <li><router-link to="./ice" >冰水主機</router-link></li>
            <li><router-link to="./solar" class="on">光電系統</router-link></li>
            <li><router-link to="./download" >下載專區</router-link></li>
              </ul>
              <div class="menuic mobile btn_menu">
                <img class="btn_pic01 btn_menuck" src="./images/icon_menu.png" alt="">
                <img class="btn_pic02 btn_menuck" src="./images/icon_close.png" alt="">
              </div>
            </div>
          </div>
          <!--小網menu-->
          <div class="open_nav">
            <ul>
              <li class="m_nav"><router-link to="/index">首頁</router-link></li>
              <li class="m_nav"><router-link to="./public">公共用電</router-link></li>
              <li class="m_nav"><router-link to="./floor" >樓層用電</router-link></li>
              <li class="m_nav"><router-link to="./ice" >冰水主機</router-link></li>
              <li class="m_nav"><router-link to="./solar" >光電系統</router-link></li>
              <li class="m_nav"><router-link to="./download" >下載專區</router-link></li>
            </ul>
            <div class="state">
              <div class="circle"></div>通訊正常
            </div>
          </div>
        </div>
        <!--內容開始-->
        <div class="contain">
          <div class="pagesize">
            <div class="main">
              <div class="lsDate">最後更新時間 {{upDateTime}}</div>
              <div class="leftSec">
                <div class="sec sec2">
                  <p>即時發電量
                    <img class="solarImg" src="./images/icon_info.png" style="  width: 18px; vertical-align: -10%; padding-top: 0px;">
                  </p>
                  <div class="solardialog" style="text-align:center">
                      <span class="green">裝置容量：</span>30.72kw<br>
                      <!-- <span class="green">發電效率：</span><br> -->
                  </div>
                  <div class="tR">
                    <p class="num">{{currentSolarKw}}<span>kW</span></p>
                  </div>
                  <div class="graphBorder"></div>
                  <p>本日累積發電量</p>
                  <div class="tR">
                    <p class="num">{{solarDayKwh}}<span>度</span></p>
                  </div>
                  <div class="graphBorder"></div>
                  <p>本月累積發電量</p>
                  <div class="tR">
                    <p class="num">{{solarMonKwh}}<span>度</span></p>
                  </div>
                  <div class="graphBorder"></div>
                  <p>{{exMon}}月容量因數
                     <img class="solarImg1" src="./images/icon_info.png" style="  width: 18px; vertical-align: -10%; padding-top: 0px;">
                  </p>
                  <div class="solardialog1" style="text-align:center">
                      <span class="green">臺北市歷年平均容量因數：</span>9.8%~11.8%<br>
                      <!-- <span class="green">發電效率：</span><br> -->
                  </div>
                  <div class="tR">
                    <p class="num">{{capacityFactorLastMon}}<span>%</span></p>
                  </div>
                </div>
                <div class="sec">
                  <p>今年累積減碳量</p>
                  <div class="tR">
                    <p class="num">{{accCO2Reduction}}<span>kg</span></p>
                  </div>
              </div>
            </div>
              <div class="rightSec">
                <div class="graph graphM mgb">
                  <p>本日分時發電量</p>
                  <Highcharts :options="SolarEtodayChart" ref="highchart" />
                  <!-- <img class="web" src="./images/graph9.png">
                  <img class="mobile" src="./images/graph2.png"> -->
                </div>
                <div class="graph graphM">
                  <p>發電歷史資訊</p>
                  <div class="graphBar">
                    <div class="interval">
                      <input type="checkbox" id="c1" name="yesOrNo" v-model="selectCheck">
                      <label for="c1">選擇區間<span></span></label>
                    </div>
                    <div class="btn_date">
                    <a @click="dateTypeSelect('date')" :class="{ dateActive: DateType === 'date'}">日</a>
                    <a @click="dateTypeSelect('month')" :class="{ dateActive: DateType === 'month'}">月</a>
                    <a @click="dateTypeSelect('year')" :class="{ dateActive: DateType === 'year'}">年</a>
                    </div>
                    <div class="interCa">
                      <div class="inputCa inputW">
                        <date-picker style="width:auto" placeholder="起始日期" v-model="beginDate" :type="DateType" valueType="format"></date-picker>
                        <!-- <input type="text" value="" placeholder="起始日期"> -->
                        <!-- <button class="calendar"></button> -->
                      </div>
                      <p class="dib" v-if="selectCheck">至</p>
                      <div class="inputCa inputW" v-if="selectCheck">
                        <date-picker style="width:auto" placeholder="結束日期" v-model="endDate" :type="DateType" valueType="format"></date-picker>
                        <!-- <input type="text" value="" placeholder="結束日期">
                        <button class="calendar"></button> -->
                      </div>
                    </div>
                    <div class="btn_confirm dib">
                      <a @click="historyQuery(DateType)">確認</a>
                    </div>
                  </div>
                  <Highcharts :options="selectIceChart" ref="highchart" />
                  <!-- <img class="web" src="./images/graph6.png">
                  <img class="mobile" src="./images/graph6_m.png"> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--footer-->
        <footer>
          <div class="pagesize">
            <div class="ftLogo"><img src="./images/logo2.png" alt="">
            </div>
            <div class="copyright">
              Copyright © All Rights Reserved.
            </div>
          </div>
        </footer>
      </div>
    </div>
</template>

<style scoped>
  @import './css/global.css';
  a {
    cursor: pointer;
  }
  .dateActive {
    background-color: #128771;
    color: white
  }
  .inputW {
    width: 170px;
  }
</style>

<script>
import importJQ from './js/common'
import $ from 'jquery'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import { mapActions, mapState, mapMutations } from 'vuex'
import { dbDataHandle } from '../store/function/index'
import { DateTime } from 'luxon'
export default {
  components: { DatePicker },
  data () {
    return {
      selectCheck: false,
      DateType: 'date',
      beginDate: DateTime.local().toFormat('yyyy-MM-dd'),
      endDate: DateTime.local().toFormat('yyyy-MM-dd'),
      exMon: DateTime.local().toFormat('MM') - 1,
      currentSolarKw: '',
      solarDayKwh: '',
      solarMonKwh: '',
      accCO2Reduction: '',
      capacityFactorLastMon: '',
      SolarEtodayChart: {
        chart: {
          type: 'column',
          backgroundColor: null,
          height: 280
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          // floating: true,
          verticalAlign: 'top',
          x: 0,
          y: 30
        },
        series: [{
          name: '發電量(度)',
          data: [],
          color: '#f7942f'
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                // align: 'center',
                // verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
        // , {
        //   // name: 'John',
        //   // data: [5, 7, 3]
        // }]
      },
      selectIceChart: {
        chart: {
          type: 'line',
          backgroundColor: null,
          height: 280
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          // floating: true,
          verticalAlign: 'top',
          x: 0,
          y: 30
        },
        series: [{
          name: '發電量(度)',
          data: [],
          color: '#f7942f'
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                // align: 'center',
                // verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
        // , {
        //   // name: 'John',
        //   // data: [5, 7, 3]
        // }]
      }
    }
  },
  methods: {
    ...mapMutations([
      'GETUPDATE_TIME'
    ]),
    ...mapActions([
      'getdayData',
      'getHourData',
      'getDayInterval',
      'getMonData',
      'getLastMerged',
      'getMonInterval',
      'getYearInterval'
    ]),
    async getSolarHourChart () {
      await this.getHourData()
      for (let i = 0; i < this.EhourData.data.length; i++) {
        this.SolarEtodayChart.xAxis.categories.push(this.EhourData.data[i].hhmm)
        this.SolarEtodayChart.series[0].data.push(this.handleNum(this.EhourData.data[i].detail.public.pv))
      }
    },
    async getCurrentSolarKw () {
      await this.getLastMerged()
      this.currentSolarKw = this.handleNum(this.lastCurrentData.data.detail.RE_SOLAR[0].genkW)
    },
    async culSolarDayKwh () {
      const date = {
        beginDate: DateTime.local().toFormat('yyyy-MM-dd'),
        endDate: DateTime.local().toFormat('yyyy-MM-dd')
      }
      await this.getDayInterval(date)
      this.solarDayKwh = this.handleNum(this.InterData.data[0].detail.public.pv)
    },
    async culSolarMonKwh () {
      const date = {
        beginDate: DateTime.local().toFormat('yyyy-MM'),
        endDate: DateTime.local().toFormat('yyyy-MM')
      }
      await this.getMonInterval(date)
      this.solarMonKwh = this.handleNum(this.MonInterData.data[0].detail.public.pv)
    },
    async getAccCO2Reduction () {
      const token = VueCookies.get('Authorization')
      const accCO2Reduction = await axios.post('/api/ems/shilin/accCO2Reduction',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      this.accCO2Reduction = this.handleNum(accCO2Reduction.data.data, 0)
    },
    async getcapacityFactorLastMon () {
      const token = VueCookies.get('Authorization')
      const capacityFactorLastMon = await axios.post('/api/ems/shilin/capacityFactorLastMon',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (capacityFactorLastMon.data.success) {
        this.capacityFactorLastMon = this.handleNum(capacityFactorLastMon.data.data.capacityFactor, 0)
      }
    },
    handleNum (val, num) {
      return dbDataHandle(val, num)
    },
    getIceChart (arr) {
      this.selectIceChart.series[0].data = []
      this.selectIceChart.xAxis.categories = []
      for (let i = 0; i < arr.data.length; i++) {
        if (arr.data[i].hhmm) {
          this.selectIceChart.xAxis.categories.push(arr.data[i].hhmm)
        } else if (arr.data[i].date) {
          this.selectIceChart.xAxis.categories.push(arr.data[i].date)
        }
        this.selectIceChart.series[0].data.push(this.handleNum(arr.data[i].detail.public.pv))
      }
    },
    dateTypeSelect (str) {
      this.DateType = str
      if (str === 'date') {
        this.beginDate = DateTime.local().toFormat('yyyy-MM-dd')
        this.endDate = DateTime.local().toFormat('yyyy-MM-dd')
      }
      if (str === 'month') {
        this.beginDate = DateTime.local().toFormat('yyyy-MM')
        this.endDate = DateTime.local().toFormat('yyyy-MM')
      }
      if (str === 'year') {
        this.beginDate = DateTime.local().toFormat('yyyy')
        this.endDate = DateTime.local().toFormat('yyyy')
      }
    },
    async historyQuery (type) {
      if (!this.selectCheck) {
        if (type === 'date') {
          await this.getHourData(this.beginDate)
          this.getIceChart(this.EhourData)
        } else if (type === 'month') {
          await this.getdayData(this.beginDate)
          this.getIceChart(this.EdayData)
        } else if (type === 'year') {
          await this.getMonData(this.beginDate)
          this.getIceChart(this.EmonData)
        }
      } else {
        const date = {
          beginDate: this.beginDate,
          endDate: this.endDate
        }
        if (type === 'date') {
          await this.getDayInterval(date)
          this.getIceChart(this.InterData)
        } else if (type === 'month') {
          await this.getMonInterval(date)
          this.getIceChart(this.MonInterData)
        } else if (type === 'year') {
          await this.getYearInterval(date)
          this.getIceChart(this.YearInterData)
        }
      }
    }
  },
  async mounted () {
    await importJQ()
    this.getSolarHourChart()
    this.getCurrentSolarKw()
    this.culSolarDayKwh()
    this.culSolarMonKwh()
    this.getAccCO2Reduction()
    this.getcapacityFactorLastMon()
    this.historyQuery(this.DateType)
    this.GETUPDATE_TIME()
    $(function () {
      $(document).ready(function () {
        var $win = $(window)
        $win.bind('resize', function () {
          try {
            $('.contain').css('min-height', $win.height() - $('footer').outerHeight() - $('.contain').offset().top)
          } catch (err) {}
        }).resize()
      })
    })
  },
  computed: {
    ...mapState([
      'upDateTime',
      'EhourData',
      'EdayData',
      'EmonData',
      'lastCurrentData',
      'InterData',
      'MonInterData',
      'YearInterData'
    ])
  }
}
</script>
